.roomsCarouselWrapper {
  /* background-color: pink; */
}

.RoomsCarousel {
  background: linear-gradient(180deg, rgba(22, 30, 96, 0) 0%, rgba(141, 141, 141, 0.51) 52.95%, rgba(8, 5, 37, 0) 100%);
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot_active {
  background: #000;
}

.arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-color: #fff;
  cursor: pointer;

  /* border-radius: 50%; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--chakra-colors-gray-100);
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.13);
}

.arrow svg {
  width: 30px;
  height: 30px;
  fill: var(--chakra-colors-gray-800);
}

.arrow:disabled {
  opacity: 0.3;
}

.arrow_left {
  left: 0;
}

.arrow_right {
  left: auto;
  right: 0;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
