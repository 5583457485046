.root {
  position: absolute;
  z-index: 50;
  transition: all 0.3s;
  background-color: transparent;
}

.fixed {
  position: fixed;
  background-color: #112;
  transition: all 0.3s;
}

.mobileMenuOpen{
  background-color: var(--chakra-colors-black);
}

.firstRow {
  border-bottom: 1px solid var(--chakra-colors-whiteAlpha-600);
}

.external_links a:not(:last-child) span {
  display: inline-block;
  margin-right: 10px;
  border-right: 1px solid var(--chakra-colors-gray-100);
  padding-right: 10px;
}

.mobileNavWrapper {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: var(--chakra-colors-black);
  display: flex;
  flex-direction: column;
  align-items: center;

  /* justify-content: space-between; */
}

.navBarLogoWrapper {
  display: inline-flex;
  width: auto;
  align-items: center;
}

.navBarLogo {
  width: 100px;
  flex: 1;
  flex-basis: 100px;
}

.navBarLogoText {
  flex: 1;
  display: block;
  font-size: 24px;
  white-space: nowrap;
  color: white;
  margin-left: 10px;
  font-weight: bold;
  font-family: Merriweather, serif;
  text-shadow: 0 2px 3px #666;
}

@media screen and (max-width: 768px) {
  .navBarLogoWrapper {
    display: flex;
    flex-direction: column;
  }

  .navBarLogoText {
    font-size: 20px;
  }

  .navBarLogo {
    flex: 1;
    height: 20px;
    width: 80px;
  }

  /* .mobileNavSocialLinks {
    margin-top: auto;
    background-color: red;
  } */

  .mobileNavFooterWrapper {
    padding: 20px 0;
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .mobileNavSocialLink {
    font-size: 1.5rem;
    padding: 0 10px;

    /* margin: 0 15px; */
    text-align: center;
    color: aliceblue;
  }
}
