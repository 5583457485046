.sectionTitleWrapper {
  /* background-color: pink; */
}

.root {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

.divider::after {
  display: block;
  height: 3px;
  width: 100px;
  background-color: var(--chakra-colors-primary);
  content: " ";
  margin: auto;
  transform: translateY(-1px);
}
