.column {
  padding: 0 15px;
}

.list_item {
  list-style: none;
  margin-bottom: 7px;
}

.column_heading {
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.social_link_item {
  color: #aaa;
}

.social_link_item svg {
  font-size: 22px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}
