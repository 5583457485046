.subtitle {
  position: relative;
}

.subtitle::before {
  position: absolute;
  left: 0;
  top: -7px;
  display: block;
  content: " ";
  height: 3px;
  width: 100px;
  background-color: var(--chakra-colors-primary);
}

.description_wrapper {
  font-size: 1rem;
  line-height: 2rem;
  color: var(--chakra-colors-gray-800);
}

.description_wrapper p {
  margin-bottom: 1rem;
}